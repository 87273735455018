
import {defineComponent} from "vue";

export default defineComponent ({
  name: "Image",
  props: {
    link: {
      type: String,
      required: true
    }
  },
})
